import React, { useEffect, useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import Loader from "react-loaders"
import { ReactTabulator } from "react-tabulator"
import colors from "utils/colors"
import * as ReactDOMClient from "react-dom/client"
import api from "services/api.service"
import { userService } from "services/user.service"
import { customFormatter } from "utils/tabulator"

const ObjectivesGrid = ({
  objectives,
  isLoading,
  setObjective,
  toggleEditModal,
  reloadGrid,
  addMeasure,
  scenario,
}) => {
  var tabulatorRef = useRef(null)
  const currentUser = userService.getLoggedInUser()

  const onAddMeasure = objectiveId => {
    addMeasure(objectiveId)
  }

  const handleNamePress = (e, objective) => {
    e && e.preventDefault()
    console.log(objective)
    if (objective.isMeasure) {
      let obj = objectives.find(
        x => x.measures.find(m => m.measureID == objective.measureID) != null
      )
      setObjective({
        ...objective,
        objectiveID: obj.objectiveID,
      })
    } else {
      setObjective(objective)
    }
    toggleEditModal()
  }

  const onMoveDown = async (e, rowData) => {
    e && e.preventDefault()
    if (rowData.isMeasure) {
      let objWithMeasure = objectives.find(
        x => x.measures.find(m => m.measureID == rowData.measureID) != null
      )
      let originalMeasure = objWithMeasure.measures.find(
        x => x.measureID == rowData.measureID
      )
      let originalMeasureIdx = objWithMeasure.measures.indexOf(originalMeasure)
      let nextMeasure = objWithMeasure.measures[originalMeasureIdx + 1]
      if (nextMeasure != undefined) {
        let temp = nextMeasure.orderNum
        nextMeasure.orderNum = originalMeasure.orderNum
        originalMeasure.orderNum = temp

        await api.updateMeasure(currentUser.userID, originalMeasure)
        await api.updateMeasure(currentUser.userID, nextMeasure)
        await reloadGrid(true)
      }
    } else {
      let originalObj = objectives.find(
        x => x.objectiveID == rowData.objectiveID
      )
      let originalObjIdx = objectives.indexOf(originalObj)
      let nextObj = objectives[originalObjIdx + 1]
      if (nextObj != undefined) {
        let temp = nextObj.orderNum
        nextObj.orderNum = originalObj.orderNum
        originalObj.orderNum = temp
        await api.updateObjective(currentUser.userID, originalObj)
        await api.updateObjective(currentUser.userID, nextObj)
        await reloadGrid(true)
      }
    }
  }

  const onMoveUp = async (e, rowData) => {
    e && e.preventDefault()
    if (rowData.isMeasure) {
      let objWithMeasure = objectives.find(
        x => x.measures.find(m => m.measureID == rowData.measureID) != null
      )
      let originalMeasure = objWithMeasure.measures.find(
        x => x.measureID == rowData.measureID
      )
      let originalMeasureIdx = objWithMeasure.measures.indexOf(originalMeasure)
      let prevMeasure = objWithMeasure.measures[originalMeasureIdx - 1]
      if (prevMeasure != undefined) {
        let temp = prevMeasure.orderNum
        prevMeasure.orderNum = originalMeasure.orderNum
        originalMeasure.orderNum = temp

        await api.updateMeasure(currentUser.userID, originalMeasure)
        await api.updateMeasure(currentUser.userID, prevMeasure)
        await reloadGrid(true)
      }
    } else {
      let originalObj = objectives.find(
        x => x.objectiveID == rowData.objectiveID
      )
      let originalObjIdx = objectives.indexOf(originalObj)
      let prevObj = objectives[originalObjIdx - 1]
      if (prevObj != undefined) {
        let temp = prevObj.orderNum
        prevObj.orderNum = originalObj.orderNum
        originalObj.orderNum = temp
        await api.updateObjective(currentUser.userID, originalObj)
        await api.updateObjective(currentUser.userID, prevObj)
        await reloadGrid(true)
      }
    }
  }

  const columns = [
    {
      title: "Objective",
      field: "objectiveName",
      //   headerFilter: "input",
      widthGrow: 3,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a
          style={{ width: "100px", display: "inline-block" }}
          href="#"
          onClick={e => handleNamePress(e, rowData)}
        >
          {cellValue}
        </a>
      )),
    },
    {
      title: "Select",
      formatter: "rowSelection",
      // titleFormatter: "rowSelection",
      titleFormatter: function (cell, formatter) {
        cell.getElement().classList.add("text-center")
        return cell.getValue()
      },
      hozAlign: "center",
      headerSort: false,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect()
      },
      width: 100,
    },
    {
      title: `Add ${scenario.subCriteriaName}`,
      field: "objectiveID",
      width: 200,
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <button
          className="btn btn-primary btn-sm"
          onClick={e => onAddMeasure(rowData.objectiveID)}
        >
          + Add {scenario.subCriteriaName}
        </button>
      )),
    },
    {
      title: "Move",
      field: "objectiveID",
      width: 250,
      headerSort: false,
      formatter: customFormatter(({ rowData, cellValue }) => (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <a
            href="#"
            onClick={e => onMoveUp(e, rowData)}
            style={{ fontSize: "13px" }}
          >
            <i className="fas fa-arrow-up"></i> Up
          </a>
          <a
            href="#"
            style={{ marginLeft: "10px", fontSize: "13px" }}
            onClick={e => onMoveDown(e, rowData)}
          >
            <i className="fas fa-arrow-down"></i> Down
          </a>
        </div>
      )),
    },
  ]

  return (
    <React.Fragment>
      {isLoading && (
        <Loader
          type="line-scale-pulse-out"
          color={colors.primary}
          style={{ textAlign: "center" }}
        />
      )}
      {!isLoading && objectives.length > 0 && (
        <ReactTabulator
          onRef={r => (tabulatorRef.current = r.current)}
          columns={columns}
          data={objectives}
          options={{
            // movableRows: true,
            // layout: "fitColumns",
            // cssClass: "table-borderless",
            dataTree: true,
            dataTreeStartExpanded: true,
            dataTreeCollapseElement:
              "<i class='fas fa-minus-square' style=\"margin-right:10px\"></i>",
            dataTreeExpandElement:
              "<i class='fas fa-plus-square' style=\"margin-right:10px\"></i>",
          }}
        />
      )}
    </React.Fragment>
  )
}

export default ObjectivesGrid
