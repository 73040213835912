import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import Loader from "react-loaders"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import colors from "utils/colors"
import anychart from "anychart"
import useFetcher from "hooks/useFetcher"

const IntakeFinancialChartsModal = ({ isOpen, toggleModal, alternatives, scenarioId }) => {
  const currentUser = userService.getLoggedInUser()

  useEffect(() => {
    anychart.licenseKey("definitiveinc.com-d85807d1-4c76171e")
  }, [])

  useEffect(() => {
    if (isOpen) {
      loadCharts()
    }
  }, [isOpen])

  const loadCharts = async () => {
    let altIds = alternatives.map(x => x.alternativeid).join(",")
    api.getAlternativeListChart(altIds, "BCD_SponsorNumber", 0, scenarioId).then(resp => {
      renderPieChart(resp, "sponsornumber")
    })
    api.getAlternativeListChart(altIds, "BCD_SponsorCost", 0, scenarioId).then(resp => {
      renderPieChart(resp, "sponsorcost")
    })
    api.getAlternativeListChart(altIds, "BCD_RatingNumber", 0, scenarioId).then(resp => {
      renderPieChart(resp, "ratingnumber")
    })
    api.getAlternativeListChart(altIds, "BCD_RatingCost", 0, scenarioId).then(resp => {
      renderPieChart(resp, "ratingcost")
    })
    api.getAlternativeListChart(altIds, "BCD_Phase", 0, scenarioId).then(resp => {
      renderPhaseChart(resp, "phase")
    })
    api.getAlternativeListChart(altIds, "BCD_Cost", 0, scenarioId).then(resp => {
      renderBarChart(resp, "cost")
    })
    api.getAlternativeListChart(altIds, "BCD_Benefit", 0, scenarioId).then(resp => {
      renderBarChart(resp, "benefit")
    })
    api.getAlternativeListChart(altIds, "BCD_Return", 0, scenarioId).then(resp => {
      renderBarChart(resp, "return")
    })
    api.getAlternativeListChart(altIds, "BCD_ROI", 0, scenarioId).then(resp => {
      renderBarChart(resp, "roi")
    })
    api.getAlternativeListChart(altIds, "BCD_IRR", 0, scenarioId).then(resp => {
      renderBarChart(resp, "irr")
    })
    api.getAlternativeListChart(altIds, "BCD_Payback", 0, scenarioId).then(resp => {
      renderBarChart(resp, "payback")
    })
  }

  const renderPieChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    let chartData = anychart.data.set(JSON.parse(json))
    chartData.mapAs({ x: 0, value: 1, ToolTip: 2 })
    var chart = anychart.pie(chartData)
    chart.tooltip().useHtml(true)
    var tooltip = chart.tooltip()
    tooltip.titleFormat("{%x}")
    tooltip.format("{%ToolTip}")
    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.title("By $").radius("43%").innerRadius("30%")
    chart.container(divId)
    chart.draw()
    chart.credits().enabled(false)
    chart.draw()
  }

  const renderPhaseChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    let chartData = anychart.data.set(JSON.parse(json))
    var chart = anychart.funnel(chartData)
    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.tooltip().useHtml(true)
    var tooltip = chart.tooltip()
    tooltip.titleFormat("{%x}")
    tooltip.format("{%ToolTip}")
    chart.container(divId)
    chart.draw()
    chart.credits().enabled(false)
    chart.draw()
  }

  const renderBarChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    let chartData = anychart.data.set(JSON.parse(json))
    var chart = anychart.column()
    var series = chart.column(chartData)
    var yLabels = chart.yAxis().labels()
    yLabels.format(function () {
      return "$" + anychart.format.number(this.value, 2, ".", ",")
    })
    chart.tooltip().format(function (e) {
      var value = anychart.format.number(this.value, 2, ".", ",")
      return "Return: $" + value
    })
    // set lowest line marker
    var lineMarker = chart.lineMarker()
    lineMarker.value(response.chartData)
    lineMarker.stroke({
      thickness: 2,
      color: "#FF0000",
    })
    chart.background().stroke(".5 gray")
    chart.animation(true)
    chart.container(divId)
    chart.credits().enabled(false)
    chart.draw()
  }

  const renderGanttChart = (response, divId) => {
    if (
      document.getElementById(divId) !== undefined &&
      document.getElementById(divId) !== null
    ) {
      document.getElementById(divId).innerHTML = ""
    }
    let obj = eval("(" + response.chartArray.replaceAll("'", '"') + ")")
    let json = JSON.stringify(obj)
    var data = JSON.parse(json)
    var treeData = anychart.data.tree(data, "as-tree")
    var chart = anychart.ganttProject()
    chart.data(treeData)
    var ganttDiv = document.getElementById(divId)
    var items = data.length
    var gh = 100 + 20 * items
    ganttDiv.style.height = gh + "px"
    var newColumn = chart.dataGrid().column(0)
    newColumn.width(60)
    newColumn.title("ID")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("<div style='text-align: center'>{%id}</div>")
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)
    var newColumn = chart.dataGrid().column(1)
    newColumn.width(500)
    newColumn.title("Name")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().fontColor("#000000")
    newColumn.labels().format("{%name}")
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)
    var newColumn = chart.dataGrid().column(2)
    newColumn.width(90)
    newColumn.title("Start")
    newColumn.title().fontColor("#64b5f6")
    newColumn.title().fontWeight(600)
    newColumn.labels().useHtml(true)
    newColumn.labels().fontColor("#000000")
    newColumn
      .labels()
      .format(
        "<div style='text-align: center'>{%actualStart}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn.depthPaddingMultiplier(20)
    newColumn.collapseExpandButtons(true)
    var newColumn2 = chart.dataGrid().column(3)
    newColumn2.width(90)
    newColumn2.title("Finish")
    newColumn2.title().fontColor("#64b5f6")
    newColumn2.title().fontWeight(600)
    newColumn2.labels().useHtml(true)
    newColumn2.labels().fontColor("#000000")
    newColumn2
      .labels()
      .format(
        "<div style='text-align: center'>{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</div>"
      )
    newColumn2.depthPaddingMultiplier(20)
    newColumn2.collapseExpandButtons(true)
    var dataGrid = chart.dataGrid()
    var dgTooltip = dataGrid.tooltip()
    dgTooltip.useHtml(true)
    dgTooltip.format(
      "<span>" +
        "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
        "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
        "% Complete: {%progress}<br><br>" +
        "{%projecthealthcomments}"
    )
    chart.getTimeline().tooltip().useHtml(true)
    chart
      .getTimeline()
      .tooltip()
      .format(
        "<span>" +
          "{%actualStart}{dateTimeFormat:MM/dd/yyyy} – " +
          "{%actualEnd}{dateTimeFormat:MM/dd/yyyy}</span><br><br>" +
          "% Complete: {%progress}<br><br>" +
          "{%projecthealthcomments}"
      )
    var header = chart.getTimeline().header()
    var mlevel = header.level(2)
    //mlevel.enabled(false);
    // set zoom levels of the scale
    chart
      .getTimeline()
      .scale()
      .zoomLevels([
        [
          { unit: "month", count: 1 },
          { unit: "quarter", count: 1 },
          { unit: "year", count: 1 },
        ],
      ])
    // header.level(0).format("{%tickValue}{dateTimeFormat:MMM}");
    chart.xScale().fiscalYearStartMonth("1") //(<%=HiddenFYMonth.Value%>);
    chart.container(divId)
    // initiate drawing the chart
    chart.draw()
    chart.credits().enabled(false)
    chart.fitAll()
  }

  const sponsoringBusinessUnitLabel = useFetcher(() =>
    api.getEnterpriseLabel(currentUser.enterpriseID, "Sponsoring business unit")
  )

  return (
    <Modal
    backdrop="static"
      isOpen={isOpen}
      fullscreen={true}
      toggle={() => {
        toggleModal()
      }}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Intake and Financials
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleModal()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Row className="mb-3">
          <Col md="4">
            <Row>
              <h5 className="text-center">By {sponsoringBusinessUnitLabel}</h5>
              <Col md="6">
                <div
                  id="sponsornumber"
                  style={{ width: "100%", height: "300px" }}
                ></div>
              </Col>
              <Col md="6">
                <div
                  id="sponsorcost"
                  style={{ width: "100%", height: "300px" }}
                ></div>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <h5 className="text-center">By Phase</h5>
            <div id="phase" style={{ width: "100%", height: "300px" }}></div>
          </Col>
          <Col md="4">
            <Row>
              <h5 className="text-center">By Group Rating</h5>
              <Col md="6">
                <div
                  id="ratingnumber"
                  style={{ width: "100%", height: "300px" }}
                ></div>
              </Col>
              <Col md="6">
                <div
                  id="ratingcost"
                  style={{ width: "100%", height: "300px" }}
                ></div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="4">
            <h5 className="text-center">By Cost ($)</h5>
            <div id="cost" style={{ width: "100%", height: "400px" }}></div>
          </Col>
          <Col md="4">
            <h5 className="text-center">By Benefit ($)</h5>
            <div id="benefit" style={{ width: "100%", height: "400px" }}></div>
          </Col>
          <Col md="4">
            <h5 className="text-center">By Return ($)</h5>
            <div id="return" style={{ width: "100%", height: "400px" }}></div>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h5 className="text-center">By ROI (%)</h5>
            <div id="roi" style={{ width: "100%", height: "400px" }}></div>
          </Col>
          <Col md="4">
            <h5 className="text-center">By IRR (%)</h5>
            <div id="irr" style={{ width: "100%", height: "400px" }}></div>
          </Col>
          <Col md="4">
            <h5 className="text-center">By Payback Period</h5>
            <div id="payback" style={{ width: "100%", height: "400px" }}></div>
          </Col>
        </Row>
      </div>

      <div
        className="modal-footer"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={toggleModal}
        >
          Close
        </button>
      </div>
    </Modal>
  )
}

export default IntakeFinancialChartsModal
