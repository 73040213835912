import {
  swalWithConfirmAndCancelButtons,
  swalWithConfirmButton,
} from "components/custom/swal"
import React, { useRef, useState } from "react"
import { createRoot } from "react-dom/client"
import { ReactTabulator } from "react-tabulator"
import { toast } from "react-toastify"
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { exportTableToExcel } from "utils/exportToExcel"
import { customFormatter } from "utils/tabulator"
import {
  getSelectHtmlFilterValues,
  getSelectedVisibleRows,
} from "utils/tabulatorFilter"

const IdeasGrid = ({ ideas, props, reload }) => {
  var tabulatorRef = useRef(null)
  const currentUser = userService.getLoggedInUser()
  const [showFilters, setShowFilters] = useState(true)
  const [showActionsDropdown, setShowActionsDropdown] = useState(false)
  const [selectedIdeas, setSelectedIdeas] = useState([])
  const [showBatchDropdown, setShowBatchDropdown] = useState(false)

  const toggleFilters = () => {
    tabulatorRef.current.element.classList.remove("show-filters")
    tabulatorRef.current.element.classList.remove("hide-filters")
    tabulatorRef.current.element.classList.add(
      !showFilters ? "show-filters" : "hide-filters"
    )
    setShowFilters(!showFilters)
  }

  const handleNamePress = (e, alternative) => {
    e && e.preventDefault()

    props.history.push(
      `/editProject/${alternative.scenarioID}/${alternative.alternativeID}`
    )
  }

  const exportTable = () => {
    let colsToExport = columns.filter(x => x.field !== undefined)
    exportTableToExcel(
      ideas,
      colsToExport.map(c => {
        return { property: c.field, heading: c.title }
      }),
      "Ideas.csv"
    )
  }

  const showSelectionAlert = () => {
    swalWithConfirmButton.fire({
      title: `You must select at least one idea to run this.`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonText: "Ok",
    })
  }

  const deleteIdeas = () => {
    let selected = [...selectedIdeas]
    if (selected.length > 0) {
      swalWithConfirmAndCancelButtons
        .fire({
          title: `Are you sure you want to delete the selected ideas?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then(async result => {
          if (result.isConfirmed) {
            let tasks = selected.map(x => async () => {
              return await api.deleteAlternative(x.alternativeID)
            })
            await Promise.all(tasks.map(t => t()))
            toast.success("Ideas deleted successfully.")
            reload()
          }
        })
    } else {
      showSelectionAlert()
    }
  }

  const promoteIdeas = async () => {
    if (selectedIdeas.length == 0) {
      swalWithConfirmButton.fire({
        title: `You cannot run this option without Ideas selected`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
    } else {
      let tasks = selectedIdeas.map(x => async () => {
        return await api.updateAlternative(currentUser.userID, {
          ...x,
          ideaOnly: false,
          limitAccess: false,
        })
      })
      await Promise.all(tasks.map(t => t()))
      toast.success("Ideas promoted successfully")
      reload()
    }
  }

  const columns = [
    {
      formatter: "rowSelection",
      titleFormatter: "rowSelection",
      hozAlign: "center",
      headerSort: false,
      cellClick: function (e, cell) {
        cell.getRow().toggleSelect()
      },
      width: 50,
    },
    {
      title: "ID",
      field: "alternativeNum",
      width: 80,
      headerFilter: "input",
      formatter: (cell, formatterParams, onRendered) => {
        return "Idea"
      },
    },
    {
      title: "Decision",
      field: "scenarioName",
      headerFilter: "select",
      headerFilterParams: {
        values: true,
        sortValuesList: "asc",
      },
    },
    {
      title: "Project",
      field: "alternativeName",
      minWidth: 400,
      headerFilter: "input",
      formatter: customFormatter(({ rowData, cellValue }) => (
        <a href="#" onClick={e => handleNamePress(e, rowData)}>
          {cellValue}
        </a>
      )),
    },
    {
      title: "Program",
      field: "program",
      headerFilter: "select",
      headerFilterFunc: "in",
      headerFilterParams: {
        values: getSelectHtmlFilterValues(
          new Set(
            ideas
              .map(u => u.program)
              .filter(x => x !== undefined && x !== null && x !== "")
          )
        ),
        sortValuesList: "asc",
        multiselect: true,
      },
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
    {
      title: "Business Case Status",
      field: "bcState",
      headerFilter: "select",
      headerFilterFunc: "in",
      headerFilterParams: {
        values: getSelectHtmlFilterValues(
          new Set(
            ideas
              .map(u => u.bcState)
              .filter(x => x !== undefined && x !== null && x !== "")
          )
        ),
        sortValuesList: "asc",
        multiselect: true,
      },
      formatter: (cell, formatterParams, onRendered) => {
        return cell.getValue()
      },
    },
  ]

  return (
    <div>
      <Row className="mb-3">
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Dropdown
              direction="start"
              className="save-user"
              isOpen={showBatchDropdown}
              toggle={() => setShowBatchDropdown(!showBatchDropdown)}
            >
              <DropdownToggle tag="button" className="btn btn-outline-primary">
                Batch Updates <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "500px", overflowX: "auto" }}>
                <DropdownItem
                  onClick={() => {
                    deleteIdeas()
                  }}
                >
                  Delete Idea(s)
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <button
              onClick={toggleFilters}
              className="btn btn-sm btn-outline-primary save-user"
            >
              {showFilters ? "Hide" : "Show"} <i className="fas fa-search"></i>{" "}
              <i className="fas fa-filter"></i>
            </button>
            <Dropdown
              direction="start"
              className="save-user"
              isOpen={showActionsDropdown}
              toggle={() => setShowActionsDropdown(!showActionsDropdown)}
            >
              <DropdownToggle tag="button" className="btn btn-outline-primary">
                Actions <i className="mdi mdi-chevron-down" />
              </DropdownToggle>
              <DropdownMenu style={{ maxHeight: "500px", overflowX: "auto" }}>
                <DropdownItem
                  onClick={() => {
                    promoteIdeas()
                  }}
                >
                  <i className="fas fa-share"></i> Promote Idea(s)
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    exportTable()
                  }}
                >
                  <i className="fas fa-table"></i> Export Table
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactTabulator
            onRef={r => (tabulatorRef.current = r.current)}
            columns={columns}
            data={ideas}
            options={{
              pagination: "local",
              paginationSize: 30,
              paginationSizeSelector: [5, 10, 20, 30, 50, 100],
              layout: columns.length > 6 ? "fitDataStretch" : "fitColumns",
              cssClass: showFilters ? "show-filters" : "hide-filters",
              maxHeight: "100%",
            }}
            events={{
              rowSelectionChanged: function (data, rows) {
                let selectedVisibleData = getSelectedVisibleRows(tabulatorRef)

                setSelectedIdeas(selectedVisibleData)
              },
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default IdeasGrid
