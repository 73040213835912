import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import Loader from "react-loaders"
import * as Yup from "yup"
import { useFormik } from "formik"
import logo from "assets/images/defprologo1_blk.png"
import soc2 from "assets/images/Soc2Combined.png"
import colors from "utils/colors"
import { authenticationService } from "services/authentication.service"
import api from "services/api.service"
import { toast } from "react-toastify"
import { swalWithConfirmButton } from "components/custom/swal"

const Login = props => {
  const enterpriseCode = props.match.params.enterpriseCode
  const dispatch = useDispatch()

  // 1 - email, 2 - email password, 3 - 2FA
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")
  const [step1Error, setStep1Error] = useState("")
  const [userInfo, setUserInfo] = useState(null)

  const [tfaCode, setTfaCode] = useState("")
  const [tfaError, setTfaError] = useState("")

  //1 - mobile, 2 - phone
  const [resendModeId, setResendModeId] = useState(1)

  const [showUnauthorized, setShowUnauthorized] = useState(false)

  const [enterprise, setEnterprise] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let userId = params.get("u")
    let code = params.get("c")
    if (
      userId != undefined &&
      userId != null &&
      code != undefined &&
      code != null
    ) {
      check2faRedirect(userId, code)
    }
    localStorage.removeItem("initialLogin")
    checkOktaUser(params.get("logout") == "true")
  }, [])

  useEffect(() => {
    loadEnterprise()
  }, [props.match.params.enterpriseCode])

  const loadEnterprise = async () => {
    if (
      enterpriseCode !== undefined &&
      enterpriseCode !== null &&
      enterpriseCode !== ""
    ) {
      let e = await api.getEnterpriseByCode(enterpriseCode)
      setEnterprise(e)
    }
  }

  const checkOktaUser = hasLoggedOut => {
    const lastUserEmail = localStorage.getItem("lastUserEmail")
    if (lastUserEmail !== undefined && lastUserEmail != null && !hasLoggedOut) {
      getUserInfo(lastUserEmail)
    }
  }

  const check2faRedirect = async (userId, code) => {
    let ret = await api.check2fa(userId, code)
    if (ret == 0) {
      setShowUnauthorized(true)
    } else {
      authenticationService.login(ret.email, ret.password, dispatch).then(
        user => {
          localStorage.setItem("lastUserEmail", ret.email)
          setIsLoading(false)
          handleRedirect(user)
        },
        error => {
          setIsLoading(false)
          setShowUnauthorized(true)
        }
      )
    }
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "" || "",
      password: "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      setError("")
      setIsLoading(true)
      console.log(props)
      if (userInfo != null && userInfo.use2FA) {
        authenticationService.authenticate(values.email, values.password).then(
          async user => {
            setIsLoading(false)
            await api.send2fa(userInfo.userID, 0)
            setStep(3)
          },
          error => {
            setIsLoading(false)
            setError("Username or password incorrect")
          }
        )
      } else {
        authenticationService
          .login(values.email, values.password, dispatch)
          .then(
            user => {
              localStorage.setItem("lastUserEmail", values.email)
              setIsLoading(false)
              handleRedirect(user)
            },
            error => {
              setIsLoading(false)
              setError("Username or password incorrect")
            }
          )
      }
    },
  })

  const getUserInfo = async email => {
    setStep1Error("")

    let u = await api.getUserInfoByEmail(email)
    setUserInfo(u)
    if (u == null) {
      setStep1Error("You are not a user of the system")
    } else {
      if (u.useAzureAD) {
        let ret = await authenticationService.loginWithMicrosoft(u.azureAppID, u.azureTenantID)
        console.log(ret)
        return
      } else if (u.useOkta) {
        if (
          props.location.state !== undefined &&
          props.location.state.from !== undefined
        ) {
          localStorage.setItem("oktaLocationFrom", props.location.state.from)
        }
        props.history.push("/loginokta", { state: { ...props.location.state } })
      } else {
        setStep(2)
        validation.setFieldValue("email", email)
        validation.setFieldValue("password", "")
        window.setTimeout(function () {
          document.getElementById("password-input").focus()
        }, 100)
      }
    }
  }

  const submit2faCode = async () => {
    setTfaError("")
    if (tfaCode == "") {
      setTfaError("Code is not valid")
    } else {
      let ret = await api.check2fa(userInfo.userID, tfaCode)
      if (ret == 0) {
        setTfaError("Code is not valid")
      } else {
        authenticationService
          .login(validation.values.email, validation.values.password, dispatch)
          .then(
            user => {
              localStorage.setItem("lastUserEmail", validation.values.email)
              setIsLoading(false)
              handleRedirect(user)
            },
            error => {
              setIsLoading(false)
              setError("Username or password incorrect")
            }
          )
      }
    }
  }

  const handleRedirect = user => {
    if (
      props.location.state &&
      props.location.state.from &&
      props.location.state.from != "" &&
      (typeof props.location.state.from === "string" ||
        props.location.state.from instanceof String)
    ) {
      if (props.location.state.from.indexOf("redirectList") > -1) {
        let listId = props.location.state.from.split("?list=")[1]
        api
          .checkUserLinkAccess(user.userID, listId, "List")
          .then(accessResponse => {
            if (accessResponse == "OK") {
              api.getAlternativeLists(user.userID, 0).then(listData => {
                let listObject = listData.find(
                  x => x.alternativeListID == listId
                )
                if (listObject != undefined) {
                  props.history.push(
                    `/projects/${listObject.scenarioID}?list=${listId}`
                  )
                } else {
                  window.location.replace(`/`)
                }
              })
            } else {
              showUnauthorizedMessage(user, accessResponse)
            }
          })
      } else if (
        props.location.state.from.indexOf("redirectBusinessCase") > -1
      ) {
        let bcId = props.location.state.from.split("?bcId=")[1]
        api
          .checkUserLinkAccess(user.userID, bcId, "Alternative")
          .then(accessResponse => {
            if (accessResponse == "OK") {
              api.getAlternativeByBusinessCaseId(bcId).then(alternativeData => {
                if (alternativeData != undefined) {
                  props.history.push(
                    `/editProject/${alternativeData.scenarioID}/${alternativeData.alternativeID}`
                  )
                } else {
                  window.location.replace(`/`)
                }
              })
            } else {
              showUnauthorizedMessage(user, accessResponse)
            }
          })
      } else {
        props.history.push(props.location.state.from)
      }
    } else {
      handleNormalFlow(user)
    }
  }

  const showUnauthorizedMessage = (user, msg) => {
    swalWithConfirmButton
      .fire({
        title: msg,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
      })
      .then(r => {
        handleNormalFlow(user)
      })
  }

  const handleNormalFlow = user => {
    if (user && user.homePagePath) {
      props.history.push(`/${user.homePagePath}`)
    } else {
      props.history.push("/models")
    }
  }

  const resend2faCode = async () => {
    await api.send2fa(userInfo.userID, resendModeId)
    toast.success("Code resent")
  }

  const hasLastUserEmail =
    localStorage.getItem("lastUserEmail") != undefined &&
    localStorage.getItem("lastUserEmail") != null &&
    localStorage.getItem("lastUserEmail") != ""

  return (
    <React.Fragment>
      <div className=""></div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          {!showUnauthorized && (
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs={8}>
                        <div className="text-primary p-4">
                          {enterpriseCode !== undefined && enterprise !== null && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: enterprise.welcomeMessage,
                              }}
                            ></div>
                          )}
                          {enterpriseCode == undefined && (
                            <>
                              <h5 className="text-primary">Welcome!</h5>
                              <p>Sign in to continue to Definitive Pro</p>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        {/* <img src={profile} alt="" className="img-fluid" /> */}
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div style={{ textAlign: "center" }}>
                      <Link
                        to="/"
                        className="auth-logo-light"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {enterpriseCode == undefined ? (
                          <img src={logo} alt="" height="74" />
                        ) : enterprise !== null ? (
                          <img src={enterprise.logoPath} alt="" height="74" />
                        ) : (
                          <></>
                        )}
                        {enterpriseCode !== undefined &&
                          enterprise !== null && (
                            <h1>{enterprise.systemName}</h1>
                          )}
                      </Link>
                    </div>
                    {step == 1 && (
                      <div className="p-2">
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                          />
                          {step1Error != "" && (
                            <div
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            >
                              {step1Error}
                            </div>
                          )}
                        </div>
                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={() => getUserInfo(email)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                    {step == 2 && (
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {error ? <Alert color="danger">{error}</Alert> : null}

                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              id="password-input"
                              name="password"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={isLoading}
                            >
                              Log In
                            </button>
                          </div>

                          {isLoading && (
                            <div
                              style={{
                                width: "100%",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              <Loader
                                type="line-scale-pulse-out"
                                color={colors.primary}
                              />
                            </div>
                          )}
                        </Form>
                      </div>
                    )}
                    {step == 3 && (
                      <div className="p-2">
                        <div
                          className="mb-3"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ width: "55%" }}>
                            <span>
                              Enter the code that has been sent to your phone
                              via text message
                            </span>
                          </div>
                          <div style={{ width: "20%" }}>
                            <Input
                              className="form-control"
                              placeholder="Code"
                              type="text"
                              onChange={e => setTfaCode(e.target.value)}
                              value={tfaCode}
                            />
                            {tfaError != "" && (
                              <div
                                className="invalid-feedback"
                                style={{ display: "block" }}
                              >
                                {tfaError}
                              </div>
                            )}
                          </div>
                          <div style={{ width: "20%" }}>
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={submit2faCode}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <div
                          className="mb-3"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Resend a code to my:</span>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="phone"
                              id="phone"
                              value="1"
                              checked={resendModeId == 1}
                              onClick={e => setResendModeId(1)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="phone"
                              style={{ marginLeft: "10px" }}
                            >
                              Phone
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="email"
                              id="email"
                              value="2"
                              checked={resendModeId == 2}
                              onClick={e => setResendModeId(2)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="email"
                              style={{ marginLeft: "10px" }}
                            >
                              Email
                            </label>
                          </div>
                          <div style={{ width: "20%" }}>
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={resend2faCode}
                            >
                              Resend
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {hasLastUserEmail && (
                    <div className="mb-3">
                      <Link
                        to="/inexistent"
                        className="text-muted"
                        onClick={e => {
                          e.preventDefault()
                          localStorage.removeItem("lastUserEmail")
                          setStep(1)
                        }}
                      >
                        <i className="mdi mdi-arrow-left me-1" />
                        Restart Login
                      </Link>
                    </div>
                  )}
                  <div className="mb-3">
                    <Link to="/forgot-password" className="text-muted">
                      <i className="mdi mdi-lock me-1" />
                      Forgot your password?
                    </Link>
                  </div>
                  <div>
                    <a
                      href="https://www.definitivepro.com"
                      className="text-dark"
                    >
                      {" "}
                      Visit the Definitive Pro website
                    </a>
                    <br></br>
                    <br></br>
                  </div>
                  <div>
                    <img
                      src={soc2}
                      alt=""
                      //className="rounded-circle"
                      height="120"
                    />
                  </div>
                  {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                  <p>
                    ©2016-2024 Definitive Business Solutions, Inc. All Rights
                    Reserved
                  </p>
                </div>
              </Col>
            </Row>
          )}
          {showUnauthorized && (
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          You are not authorized to access the system
                        </h5>
                      </div>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
