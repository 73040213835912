import PropTypes from "prop-types"
import Widgets from "components/Widgets"
import React, { createContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import { loadPageItem } from "store/actions"
import { changeNavbarParams } from "store/navbar/actions"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ConfigModal from "./ConfigModal"
import ProjectsGrid from "./ProjectsGrid"
import ProjectsContext from "./Context/ProjectsContext"

const Projects = props => {
  const dispatch = useDispatch()
  const scenarioId =
    props.match.params.scenarioId == undefined
      ? 0
      : props.match.params.scenarioId.split("?")[0]
  const currentUser = userService.getLoggedInUser()
  const { pageItem } = useSelector(state => ({
    pageItem: state.pageItem.pageItem,
  }))
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false)
  const [configItems, setConfigItems] = useState([])
  const [bcStatusOptions, setBcStatusOptions] = useState([])
  const [versionOptions, setVersionOptions] = useState([])
  const [excludedItemsOptions, setExcludedItemsOptions] = useState([])
  const [completeOptions, setCompleteOptions] = useState([])
  const [brmOptions, setBrmOptions] = useState([])
  const [sponsorOptions, setSponsorOptions] = useState([])
  const [ldoOptions, setLdoOptions] = useState([])
  const [ldoListOptions, setLdoListOptions] = useState([])
  const [pmOptions, setPmOptions] = useState([])
  const [deliveryOptions, setDeliveryOptions] = useState([])
  const [submitterOptions, setSubmitterOptions] = useState([])
  const [workflowOptions, setWorkflowOptions] = useState([])
  const [alternativePhaseOptions, setAlternativePhaseOptions] = useState([])
  const [advancedPlanningOptions, setAdvancedPlanningOptions] = useState([])
  const [cpPhaseOptions, setCpPhaseOptions] = useState([])
  const [planningOptions, setPlanningOptions] = useState([])
  const [investmentOptions, setInvestmentOptions] = useState([])
  const [decisionOptions, setDecisionOptions] = useState([])
  const [baselineLogicOptions, setBaselineLogicOptions] = useState([])
  const [programs, setPrograms] = useState([])
  let [widgets, setWidgets] = useState([])
  const [scenario, setScenario] = useState(null)
  const [enterprise, setEnterprise] = useState(null)

  useEffect(() => {
    dispatch(
      loadPageItem({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: "projects",
      })
    )
    dispatch(
      changeNavbarParams({
        userId: currentUser.userID,
        scenarioId: scenarioId,
        alternativeId: 0,
        viewName: scenarioId == 0 ? "enterpriseprojects" : "projects",
      })
    )
    loadData()
    loadLists()
    loadEnterprise()
    loadScenario()
    loadWidgets()
  }, [scenarioId])

  const loadEnterprise = async () => {
    let e = await api.getEnterprise(currentUser.enterpriseID)
    setEnterprise(e)
  }

  const loadData = async () => {
    let cfg = await api.getConfigItems(
      currentUser.userID,
      scenarioId,
      scenarioId == 0 ? "EnterpriseAlternativeList" : "AlternativeList"
    )
    setConfigItems(cfg)
  }

  const loadScenario = async () => {
    let s = await api.getScenario(scenarioId)
    setScenario(s)
  }

  const loadWidgets = async () => {
    let w = await api.getWidgetsByView("Projects", scenarioId)
    setWidgets(w)
  }

  const loadLists = () => {
    api.getList(currentUser.userID, "BCStatus").then(bc => {
      setBcStatusOptions(bc)
    })
    api.getList(currentUser.userID, "Version").then(v => {
      setVersionOptions(v)
    })
    api.getList(currentUser.userID, "YesNo").then(bc => {
      setExcludedItemsOptions(bc)
    })
    api.getList(currentUser.userID, "BaselineLogic").then(bl => {
      setBaselineLogicOptions(bl)
    })
    api.getList(currentUser.userID, "Decisions").then(dec => {
      setDecisionOptions(dec)
    })
    api.getList(currentUser.userID, "Complete").then(comp => {
      setCompleteOptions(comp)
    })
    api.getScenarioList(scenarioId, "BRMList").then(brm => {
      setBrmOptions(brm)
    })
    api.getScenarioList(scenarioId, "SponsorList").then(spons => {
      setSponsorOptions(spons)
    })
    api.getScenarioList(scenarioId, "LDO").then(ldo => {
      setLdoOptions(ldo)
    })
    api.getScenarioList(scenarioId, "LDOList").then(ldol => {
      setLdoListOptions(ldol)
    })
    api.getScenarioList(scenarioId, "PMList").then(pm => {
      setPmOptions(pm)
    })
    api.getScenarioList(scenarioId, "DeliveryPOCList").then(poc => {
      setDeliveryOptions(poc)
    })
    api.getScenarioList(scenarioId, "SubmitterList").then(sub => {
      setSubmitterOptions(sub)
    })
    api.getScenarioList(scenarioId, "WorkflowStatus").then(wf => {
      setWorkflowOptions(wf)
    })
    api.getScenarioList(scenarioId, "AlternativePhase").then(ap => {
      setAlternativePhaseOptions(ap)
    })    
    api.getList(currentUser.userID, "CPICPhase").then(cp => {
      setCpPhaseOptions(cp)
    })
    api.getScenarioList(scenarioId, "AdvancedPlanning").then(ap => {
      setAdvancedPlanningOptions(ap)
    })
    api.getScenarioList(scenarioId, "PlanningCategory").then(pl => {
      setPlanningOptions(pl)
    })
    api.getScenarioList(scenarioId, "InvestmentCategory").then(inv => {
      setInvestmentOptions(inv)
    })
    api.getPrograms(currentUser.enterpriseID).then(pr => {
      setPrograms(
        pr.map(x => {
          return { listItemID: x.programID, listItemName: x.programName }
        })
      )
    })
  }

  const toggleConfigModal = () => {
    setIsConfigModalOpen(!isConfigModalOpen)
  }

  return (
    <React.Fragment>
      <ProjectsContext.Provider
        value={{
          bcStatusOptions,
          baselineLogicOptions,
          completeOptions,
          brmOptions,
          sponsorOptions,
          ldoOptions,
          ldoListOptions,
          pmOptions,
          deliveryOptions,
          submitterOptions,
          workflowOptions,
          alternativePhaseOptions,
          advancedPlanningOptions,
          cpPhaseOptions,
          planningOptions,
          investmentOptions,
          decisionOptions,
          versionOptions,
          programs,
          excludedItemsOptions
        }}
      >
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={pageItem !== undefined ? pageItem.pageSubTitle : ""}
            />
            {scenarioId != 0 && <Widgets widgets={widgets} marginTop="0px" />}
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <ProjectsGrid
                  scenarioId={scenarioId}
                  configItems={configItems}
                  toggleConfigModal={toggleConfigModal}
                  pageItem={pageItem}
                  scenario={scenario}
                  props={props}
                  loadWidgets={loadWidgets}
                  reloadData={loadData}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <ConfigModal
          isOpen={isConfigModalOpen}
          toggleModal={toggleConfigModal}
          scenarioId={scenarioId}
          reloadData={loadData}
        />
      </ProjectsContext.Provider>
    </React.Fragment>
  )
}

export default Projects
