import { TextareaAutosize } from "@material-ui/core"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import useModal from "hooks/useModalHook"
import moment from "moment"
import React, { useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { toast } from "react-toastify"
import { Col, Input, Label, Modal, Row } from "reactstrap"
import api from "services/api.service"
import { userService } from "services/user.service"
import CreateRevisionCopyModal from "./Modals/CreateRevisionCopyModal"
import { swalWithConfirmAndCancelButtons } from "components/custom/swal"
import DynamicForm from "components/custom/DynamicForm/View/DynamicForm"
import useFetcher from "hooks/useFetcher"

const GovernanceSection = ({
  activeTab,
  alternativeId,
  alternative,
  changeAlternativeProp,
  loadAlternative,
  tabPageItem,
  enterprise,
  scenario,
  fieldConfigItems,
  props,
  saveAlternative,
  sectionFormId,
  showToastChangesCallback,
  triggerFormSave,
  setTriggerFormSave,
}) => {
  const currentUser = userService.getLoggedInUser()
  const [userOptions, setUserOptions] = useState([])
  const [leadDeliveryOptions, setLeadDeliveryOptions] = useState([])
  const [workflowOptions, setWorkflowOptions] = useState([])
  const [investmentOptions, setInvestmentOptions] = useState([])
  const [businessCaseOptions, setBusinessCaseOptions] = useState([])
  const [planningOptions, setPlanningOptions] = useState([])
  // const [projectPhaseOptions, setProjectPhaseOptions] = useState([])
  const [advancedPlanningOptions, setAdvancedPlanningOptions] = useState([])
  const [cpPhaseOptions, setCpPhaseOptions] = useState([])
  const [isManageModalOpen, setIsManageModalOpen] = useState(false)
  const toggleManageModal = () => setIsManageModalOpen(!isManageModalOpen)
  const [userAlternativeAccess, setUserAlternativeAccess] = useState([])
  const [programs, setPrograms] = useState([])
  const { show: showCreateRevisionCopy, toggle: toggleCreateRevisionCopy } =
    useModal()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [changeRequestTasks, setChangeRequestTasks] = useState([])

  useEffect(() => {
    if (activeTab == "Governance") {
      loadData()
      loadChangeRequestTasks()
    }
  }, [activeTab, alternativeId])

  useEffect(() => {
    if (isManageModalOpen) {
      loadUserAlternativeAccess()
    } else {
      setUserAlternativeAccess([])
    }
  }, [isManageModalOpen])

  // useEffect(() => {
  //   if (tabPageItem != undefined) {
  //     if (tabPageItem.userRight == "full_NP") {
  //       let div = document.getElementById("healthAndStatusContainer")
  //       div.classList.add("read-only-access")
  //     } else {
  //       let div = document.getElementById("healthAndStatusContainer")
  //       div.classList.remove("read-only-access")
  //     }
  //   }
  // }, [tabPageItem])

  const loadChangeRequestTasks = async () => {
    let t = await api.getGeneralTasks(
      1,
      "Alternatives",
      alternativeId,
      currentUser.userID
    )
    setChangeRequestTasks(t)
  }

  const loadData = async () => {
    api.getScenarioList(alternative.scenarioID, "UserList").then(usrs => {
      setUserOptions(usrs)
    })
    api.getScenarioList(alternative.scenarioID, "LDO").then(leads => {
      setLeadDeliveryOptions(leads)
    })
    api
      .getScenarioList(alternative.scenarioID, "WorkflowStatus")
      .then(workflows => {
        setWorkflowOptions(workflows)
      })
    api.getList(currentUser.userID, "CPICPhase").then(cp => {
      setCpPhaseOptions(cp)
    })
    api.getScenarioList(alternative.scenarioID, "AdvancedPlanning").then(ap => {
      setAdvancedPlanningOptions(ap)
    })
    api.getList(currentUser.userID, "BCStatus").then(business => {
      setBusinessCaseOptions(business)
    })
    api
      .getScenarioList(alternative.scenarioID, "PlanningCategory")
      .then(plans => {
        setPlanningOptions(plans)
      })
    // let phs = await api.getList(currentUser.userID, "ProjectPhase")
    // setProjectPhaseOptions(phs)
    api.getPrograms(scenario.enterpriseID).then(p => {
      setPrograms(p)
    })

    api
      .getScenarioList(alternative.scenarioID, "InvestmentCategory")
      .then(invopt => {
        setInvestmentOptions(invopt)
      })
  }

  const loadUserAlternativeAccess = async () => {
    let data = await api.getUserAlternativeAccessData(
      alternative.scenarioID,
      alternativeId
    )
    setUserAlternativeAccess(data)
  }

  const saveUserAlternativeAccess = async () => {
    let users = userAlternativeAccess.filter(x => x.isDirty)
    if (users.length > 0) {
      let tasks = users.map(x => async () => {
        return await api.updateUserAlternativeAccessData(
          x.userID,
          alternativeId,
          x.isAlternativeUser
        )
      })
      await Promise.all(tasks.map(t => t()))
      toggleManageModal()
    }
  }

  const createSmartsheet = async () => {
    let res = await api.createSmartsheet(alternativeId)
    if (res) {
      toast.success(res)
    } else {
      toast.success("Smartsheet created")
    }
    loadAlternative()
  }
  const updateSmartsheet = async () => {
    let res = await api.updateSmartsheet(alternativeId)
    if (res) {
      toast.success(res)
    } else {
      toast.success("Smartsheet updated")
    }
    loadAlternative()
  }
  const createJira = async () => {
    let res = await api.createJiraProject(alternativeId)
    if (res) {
      toast.success(res)
    } else {
      toast.success("Jira project created")
    }
    loadAlternative()
  }

  const generateForecastCostPools = async () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `This will clear any forecast data and stage tasks set the cost pools and tasks associated with this investment category.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await saveAlternative()
          await api.alternativeApplyForecastTemplate(alternative.alternativeID)
        }
      })
  }

  const makeRevisionNewBaseline = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `The revised copy will become the new baseline and the old baseline will be deleted. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          setIsSubmitting(true)
          let url = await api.generateBusinessCaseReportBackup(
            alternative.businessCaseID
          )
          await api.updateAlternative(currentUser.userID, {
            ...alternative,
            revisionReportPath: url,
          })
          await api.acceptAlternativeRevision(alternativeId)
          props.history.goBack()
          setIsSubmitting(false)
        }
      })
  }

  const removeRevision = () => {
    swalWithConfirmAndCancelButtons
      .fire({
        title: `The revision copy will be deleted. Continue?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        reverseButtons: true,
      })
      .then(async result => {
        if (result.isConfirmed) {
          await api.removeAlternativeRevision(alternativeId)
          props.history.goBack()
        }
      })
  }

  const brmLabel = useFetcher(() =>
    api.getEnterpriseLabel(
      currentUser.enterpriseID,
      "Business Relationship Manager"
    )
  )
  const advancedPlanningLabel = useFetcher(() =>
    api.getEnterpriseLabel(currentUser.enterpriseID, "Advanced Planning")
  )
  const investmentCategoryLabel = useFetcher(() =>
    api.getEnterpriseLabel(currentUser.enterpriseID, "Investment Category")
  )
  const leadBusinessRepresentativeLabel = useFetcher(() =>
    api.getEnterpriseLabel(
      currentUser.enterpriseID,
      "Lead Business Representative"
    )
  )
  const planningCategoryLabel = useFetcher(() =>
    api.getEnterpriseLabel(currentUser.enterpriseID, "Planning Category")
  )

  return (
    <React.Fragment>
      <Row>
        <Col md="5">
          <div>
            <h5>Key Roles</h5>
          </div>
          <AlternativeConfigField
            label={leadBusinessRepresentativeLabel}
            value={alternative.leadUserID}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            options={userOptions}
            prop={"leadUserID"}
            type="select"
          />

          <AlternativeConfigField
            label={brmLabel}
            value={alternative.brmUserID}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            options={userOptions}
            prop={"brmUserID"}
            type="select"
          />

          <AlternativeConfigField
            label="Lead Delivery Organization"
            value={alternative.leadDeliveryOrganizationID}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            options={leadDeliveryOptions}
            prop={"leadDeliveryOrganizationID"}
            type="select"
          />

          <AlternativeConfigField
            label="Lead Delivery Organization POC"
            value={alternative.deliveryPOCID}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            options={userOptions}
            prop={"deliveryPOCID"}
            type="select"
          />

          <div className="mb-3">
            <Label className="form-label">Project Manager</Label>
            <select
              className="form-control form-select select2 mb-xxl-0"
              value={alternative.pmUserID || -1}
              onChange={e => {
                changeAlternativeProp("pmUserID", e.target.value)
              }}
            >
              <option value={-1}>Select...</option>
              {userOptions.map((a, idx) => {
                return (
                  <option key={idx} value={`${a.listItemID}`}>
                    {a.listItemName}
                  </option>
                )
              })}
            </select>
          </div>

          <AlternativeConfigField
            label="Business Case Status"
            value={alternative.bcState}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            options={businessCaseOptions}
            prop={"bcState"}
            type="select"
          />

          <div>
            <h5>Key Planning Milestones</h5>
          </div>
          <div className="mb-3">
            <Label className="form-label">Project Start</Label>
            <Input
              name="plannedStartDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "desiredStart",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.desiredStart) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.desiredStart).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Requirements Completion</Label>
            <Input
              name="requirementsDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "requirementsDate",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.requirementsDate) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.requirementsDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Market Research Completion</Label>
            <Input
              name="marketResearchDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "marketResearchDate",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.marketResearchDate) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.marketResearchDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>

          <div className="mb-3">
            <Label className="form-label">Procurement Package Completion</Label>
            <Input
              name="procurementPackageDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "procurementPackageDate",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.procurementPackageDate) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.procurementPackageDate).format(
                      "YYYY-MM-DD"
                    )
                  : ""
              }
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Procurement Completion</Label>
            <Input
              name="procurementDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "procurementDate",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.procurementDate) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.procurementDate).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">Project Finish</Label>
            <Input
              name="plannedFinishDate"
              type="date"
              onChange={e =>
                changeAlternativeProp(
                  "plannedFinish",
                  moment(e.target.value, "YYYY-MM-DD").format(
                    "YYYY-MM-DDThh:mm:ss"
                  )
                )
              }
              value={
                new Date(alternative.plannedFinish) >
                new Date(1900, 1, 1, 0, 0, 0, 0)
                  ? moment(alternative.plannedFinish).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>

          <div className="mb-3">
            <Label className="form-label">Planning Comments</Label>
            <TextareaAutosize
              className="form-control"
              minRows={3}
              placeholder="Provide planning information that may be beneficial to the reviewer, such as the planned use of iterative development and deployment, the plan for multiple procurements, etc."
              onChange={e =>
                changeAlternativeProp("planningComments", e.target.value)
              }
              value={alternative.planningComments}
            />
          </div>
        </Col>
        <Col md="1"></Col>
        <Col md="5">
          <div>
            <h5>For Administrator and BRM Use Only</h5>
          </div>
          <AlternativeConfigField
            label="Workflow Status"
            value={alternative.workflowStatusID}
            fieldConfigItems={fieldConfigItems}
            onChange={changeAlternativeProp}
            options={workflowOptions}
            prop={"workflowStatusID"}
            type="select"
          />

          {!alternative.ideaOnly && (
            <div>
              <h5>For Administrator Use Only</h5>
            </div>
          )}
          <div
            className={
              currentUser.roleID < 8 && !alternative.ideaOnly
                ? "always-disabled"
                : ""
            }
          >
            <div className="mb-3">
              <Label className="form-label">
                {alternative.ideaOnly ? "Share " : "Limit "} Access
              </Label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id="limitaccess"
                  defaultChecked={alternative.limitAccess}
                  disabled={currentUser.roleID < 8 && "true"}
                  onChange={e => {
                    changeAlternativeProp(
                      "limitAccess",
                      !alternative.limitAccess
                    )
                  }}
                />
                <button
                  className="btn btn-primary save-user"
                  disabled={!alternative.limitAccess}
                  onClick={toggleManageModal}
                >
                  <i className="fas fa-list"></i> Manage Access
                </button>
              </div>
            </div>

            <AlternativeConfigField
              label={advancedPlanningLabel}
              value={alternative.advancedPlanningID}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              options={advancedPlanningOptions}
              prop={"advancedPlanningID"}
              type="select"
            />

            <AlternativeConfigField
              label="CPIC Phase"
              value={alternative.cpicPhaseID}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              options={cpPhaseOptions}
              prop={"cpicPhaseID"}
              type="select"
            />

            <AlternativeConfigField
              label={investmentCategoryLabel}
              value={alternative.investmentCategoryID}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              options={investmentOptions}
              prop={"investmentCategoryID"}
              type="select"
            />

            {currentUser.roleID > 8 && (
              <button
                className="btn btn-primary mb-3"
                onClick={generateForecastCostPools}
              >
                Generate New Forecast Cost Pools and Tasks
              </button>
            )}

            <AlternativeConfigField
              label="Multi-Year"
              value={alternative.multiYear}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              prop={"multiYear"}
              type="checkbox"
            />
            <AlternativeConfigField
              label="Next Review"
              value={alternative.nextReviewDT}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              prop={"nextReviewDT"}
              type="datetime"
            />

            <AlternativeConfigField
              label={planningCategoryLabel}
              value={alternative.projectCategoryID}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              options={planningOptions}
              prop={"projectCategoryID"}
              type="select"
            />

            <AlternativeConfigField
              label="Program"
              value={alternative.programID}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              options={programs.map(p => {
                return { listItemID: p.programID, listItemName: p.programName }
              })}
              prop={"programID"}
              type="select"
            />

            <div className="mb-3">
              <Label className="form-label">Program Allocation</Label>
              <CurrencyInput
                className="form-control"
                type="text"
                suffix="%"
                value={alternative.programAllocation}
                onValueChange={(value, name, values) => {
                  changeAlternativeProp("programAllocation", value)
                }}
              />
            </div>

            <div className="mb-3">
              <Label className="form-label">Rating and Score</Label>
              <div
                style={{
                  textAlign: "left",
                  display: "flex",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                <div
                  style={{ width: "150px" }}
                  dangerouslySetInnerHTML={{ __html: alternative.ratingPill }}
                ></div>
                <div style={{ width: "150px", fontWeight: "600" }}>
                  {" "}
                  {alternative.saaScore}{" "}
                </div>
              </div>
            </div>

            <AlternativeConfigField
              label="Exclude from Decision"
              value={alternative.exclude}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              prop={"exclude"}
              type="checkbox"
            />

            <AlternativeConfigField
              label="Completed"
              value={alternative.inactive}
              fieldConfigItems={fieldConfigItems}
              onChange={changeAlternativeProp}
              prop={"inactive"}
              type="checkbox"
            />

            {(currentUser.roleID == 10 || currentUser.roleID >= 105) && (
              <>
                {!alternative.inRevision && alternative.revisionAltID === 0 && (
                  <div className="mb-3">
                    <button
                      className="btn btn-primary"
                      onClick={toggleCreateRevisionCopy}
                    >
                      Create Revision Copy
                    </button>
                  </div>
                )}
                {alternative.revisionAltID > 0 && (
                  <>
                    <div
                      className="mb-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={removeRevision}
                        disabled={isSubmitting}
                      >
                        Delete Revision
                      </button>
                      <button
                        className="btn btn-primary save-user"
                        onClick={makeRevisionNewBaseline}
                        disabled={isSubmitting}
                      >
                        Make the Revision the New Baseline
                      </button>
                    </div>
                    {changeRequestTasks.length > 0 && (
                      <div className="mb-3">
                        <Label className="form-label">Change Request</Label>
                        <select
                          className="form-control form-select select2 mb-xxl-0"
                          value={alternative.revisionTaskID}
                          onChange={e => {
                            changeAlternativeProp(
                              "revisionTaskID",
                              e.target.value
                            )
                          }}
                        >
                          <option value="0">NA</option>
                          {changeRequestTasks.map((a, idx) => {
                            return (
                              <option key={idx} value={`${a.taskID}`}>
                                CR-{a.taskNum} {a.taskName}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    )}
                    <div className="mb-3">
                      <Label className="form-label">Revision Description</Label>
                      <TextareaAutosize
                        className="form-control"
                        placeholder="Description..."
                        minRows={2}
                        onChange={e =>
                          changeAlternativeProp(
                            "revisionDescription",
                            e.target.value
                          )
                        }
                        value={alternative.revisionDescription}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {enterprise != null && (
              <>
                <div className="mb-3">
                  {enterprise.smartSheetKey != null &&
                    enterprise.smartSheetKey != "" && (
                      <>
                        {alternative.smartSheetID == null ||
                        alternative.smartSheetID <= 0 ? (
                          <button
                            className="btn btn-primary"
                            disabled={currentUser.roleID < 8 && "true"}
                            onClick={createSmartsheet}
                          >
                            Create Smartsheet
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={updateSmartsheet}
                          >
                            Update Smartsheet
                          </button>
                        )}
                      </>
                    )}
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>

      {sectionFormId != 0 && activeTab == "Governance" && (
        <>
          <Row>
            <Col md="12">
              <div className="divider"></div>
              <DynamicForm
                formId={sectionFormId}
                itemId={alternative.alternativeID}
                scenarioId={alternative.scenarioID}
                setTriggerFormSave={setTriggerFormSave}
                triggerFormSave={triggerFormSave}
                showToastChangesCallback={showToastChangesCallback}
              />
            </Col>
          </Row>
        </>
      )}

      <Modal
        backdrop="static"
        isOpen={isManageModalOpen}
        size="md"
        toggle={() => {
          toggleManageModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Limit Access
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleManageModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>
            Check all team members who need access. All other team members will
            not have access.
          </h5>
          <table
            className="table table-bordered low-padding-table"
            style={{ backgroundColor: "white" }}
          >
            <thead>
              <tr>
                <th className="col-1">Selected</th>
                <th className="col-5">Name</th>
              </tr>
            </thead>
            <tbody>
              {userAlternativeAccess.length > 0 &&
                userAlternativeAccess.map((u, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id={`check-${idx}`}
                          checked={u.isAlternativeUser}
                          onClick={e => {
                            let dataCopy = [...userAlternativeAccess]
                            let user = dataCopy.find(x => x.userID == u.userID)
                            user.isAlternativeUser = !user.isAlternativeUser
                            user.isDirty = true
                            setUserAlternativeAccess(dataCopy)
                          }}
                        />
                      </td>
                      <td>
                        {u.firstName} {u.lastName}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleManageModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary save-user"
            onClick={saveUserAlternativeAccess}
          >
            Save
          </button>
        </div>
      </Modal>
      <CreateRevisionCopyModal
        alternative={alternative}
        isOpen={showCreateRevisionCopy}
        toggleModal={toggleCreateRevisionCopy}
        props={props}
      />
    </React.Fragment>
  )
}

export const HealthAndStatusRadioButtons = React.memo(function configField({
  prop,
  value,
  onChange,
  fieldConfigItems,
}) {
  const fieldConfig = fieldConfigItems.find(
    x => x.fieldName.toLowerCase() == prop.toLowerCase()
  )
  const isHidden =
    fieldConfig !== undefined && fieldConfig.fieldStatus == "hide"
  const isReadOnly =
    fieldConfig !== undefined && fieldConfig.fieldStatus == "read only"

  if (fieldConfig == undefined || isHidden) return null

  return (
    <div className="mb-3" style={{ display: "flex", flexDirection: "row" }}>
      <Label style={{ width: "300px" }}>{fieldConfig.fieldLabel}</Label>
      <div className="form-check">
        <Input
          type="radio"
          className="form-check-input"
          checked={value == 1}
          onClick={e => {
            onChange(prop, 1)
          }}
          disabled={isReadOnly}
        />
        <Label
          className="form-check-label"
          style={{ color: "red", marginLeft: "5px" }}
        >
          <b>Red</b>
        </Label>
      </div>
      <div className="form-check">
        <Input
          style={{ marginLeft: "10px" }}
          type="radio"
          className="form-check-input"
          checked={value == 2}
          onClick={e => {
            onChange(prop, 2)
          }}
          disabled={isReadOnly}
        />
        <Label
          className="form-check-label"
          style={{ color: "gold", marginLeft: "5px" }}
        >
          <b>Yellow</b>
        </Label>
      </div>
      <div className="form-check">
        <Input
          style={{ marginLeft: "10px" }}
          type="radio"
          className="form-check-input"
          checked={value == null || value == 0 || value == 3}
          onClick={e => {
            onChange(prop, 3)
          }}
          disabled={isReadOnly}
        />
        <Label
          className="form-check-label"
          style={{ color: "green", marginLeft: "5px" }}
        >
          <b>Green</b>
        </Label>
      </div>
    </div>
  )
})

export const AlternativeConfigField = React.memo(function configField({
  label,
  prop,
  value,
  onChange,
  options,
  fieldConfigItems,
  type,
  format,
}) {
  const fieldConfig = fieldConfigItems.find(
    x => x.fieldName.toLowerCase() == prop.toLowerCase()
  )
  let classes = "form-control form-select select2 mb-xxl-0 "

  classes +=
    fieldConfig == undefined || fieldConfig.fieldStatus == "show"
      ? ""
      : fieldConfig.fieldStatus == "read only"
      ? "always-disabled"
      : "always-hidden"

  const isHidden =
    fieldConfig !== undefined && fieldConfig.fieldStatus == "hide"
  const isReadOnly =
    fieldConfig !== undefined && fieldConfig.fieldStatus == "read only"

  if (isHidden) return null
  if (type == undefined || type == "select") {
    return (
      <div className={`mb-3   ${isReadOnly ? "always-disabled" : ""}`}>
        <Label className="form-label">{label}</Label>
        <select
          className={classes}
          value={value}
          onChange={e => {
            onChange(prop, e.target.value)
          }}
        >
          <option value={-1}>Select {label}</option>
          {options.map((a, idx) => {
            return (
              <option key={idx} value={`${a.listItemID}`}>
                {a.listItemName}
              </option>
            )
          })}
        </select>
      </div>
    )
  } else if (type == "date") {
    return (
      <div className={`mb-3   ${isReadOnly ? "always-disabled" : ""}`}>
        <Label className="form-label">{label}</Label>
        <Input
          type="date-local"
          // disabled={currentUser.roleID < 8 && "true"}
          onChange={e => {
            let newDate = moment(e.target.value).format("MM/DD/YYYY")
            onChange(prop, newDate)
          }}
          value={
            new Date(value) > new Date(1900, 1, 1, 0, 0, 0, 0)
              ? moment(value).format("MM/DD/YYYY")
              : ""
          }
        />
      </div>
    )
  } else if (type == "datetime") {
    return (
      <div className={`mb-3   ${isReadOnly ? "always-disabled" : ""}`}>
        <Label className="form-label">{label}</Label>
        <Input
          type="datetime-local"
          // disabled={currentUser.roleID < 8 && "true"}
          onChange={e => {
            let newDate = moment(e.target.value).format("YYYY-MM-DDTHH:mm")
            onChange(prop, newDate)
          }}
          value={
            new Date(value) > new Date(1900, 1, 1, 0, 0, 0, 0)
              ? moment(value).format("YYYY-MM-DDTHH:mm")
              : ""
          }
        />
      </div>
    )
  } else if (type == "text") {
    return (
      <div className={`mb-3   ${isReadOnly ? "always-disabled" : ""}`}>
        <Label className="form-label">{label}</Label>
        <Input
          type="text"
          // disabled={currentUser.roleID < 8 && "true"}
          onChange={e => {
            onChange(prop, e.target.value)
          }}
          value={value}
        />
      </div>
    )
  } else if (type == "checkbox") {
    return (
      <div
        className={`mb-3  form-check ${isReadOnly ? "always-disabled" : ""}`}
      >
        <Input
          type="checkbox"
          className="form-check-input"
          checked={value}
          onClick={e => {
            onChange(prop, !value)
          }}
        />
        <Label className="form-check-label">{label}</Label>
      </div>
    )
  } else {
    return null
  }
})
export default GovernanceSection
